import { Link } from "react-router-dom"
import { ReactComponent as X } from "../assets/icons/X.svg"
import { ReactComponent as APILogo } from "../assets/logos/APILogo.svg"
import { ButtonMedium, Variant } from "../components/atoms/Buttons/ButtonMedium"
import { ReactComponent as Arrow } from "../assets/icons/Arrow.svg"

export default function NotFound() {
  return (
    <div className="flex h-screen w-full justify-center items-center">
      <div className="grid grid-cols-[auto_1fr] w-max gap-[56px]">
        <div className="flex items-center">
          <X />
        </div>
        <div className="max-w-md">
          <APILogo className="mb-[40px]" />
          <div className="flex flex-col gap-[12px] mb-[32px]">
            <h1>404</h1>
            <h3>There’s a problem getting the requested URL.</h3>
          </div>
          <Link to="/">
            <ButtonMedium iconRight={<Arrow />} variant={Variant.Tertiary}>
              Home
            </ButtonMedium>
          </Link>
        </div>
      </div>
    </div>
  )
}
