import style from "./RuleItem.module.css";
import { RuleItemActions } from "./RuleItemActions";

type Props = {
  children: React.ReactElement<typeof RuleItemActions>;
  label: string;
};

export const RuleItem = ({ label, children }: Props) => {
  return (
    <div className={style.container} data-rules-group-rule-item>
      <div className={style.label}>
        <span>{label}</span>
      </div>
      {children}
    </div>
  );
};
