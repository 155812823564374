import style from "./Body2.module.css";

import { Properties } from "csstype";

type Props = {
  fontWeight?: Properties["fontWeight"];
  lineHeight?: Properties["lineHeight"];
};

export const Body2 = ({
  children,
  fontWeight,
  lineHeight,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement> & Props) => {
  const additionalStyles: Pick<Props, "fontWeight" | "lineHeight"> = { fontWeight, lineHeight };

  return (
    <p className={style.text} style={additionalStyles} {...props}>
      {children}
    </p>
  );
};
