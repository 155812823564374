import { Input } from "../../atoms/input/Input";
import { ButtonLarge, Variant } from "../../atoms/Buttons/ButtonLarge";
import { Modal } from "../../atoms/modal/Modal";
import { Body1 } from "../../atoms/text/Body1";

export type ApiKeyData = {
  key: string;
  name: string;
};

export type ApiToken = {
  key: string;
  id: string;
  expiration: string;
};

export const ApiModal = ({
  apiKey,
  onClose,
  onSubmit,
}: {
  onClose: () => void;
  onSubmit: (formData: ApiKeyData) => void;
  apiKey: string | null;
}) => {
  if (!apiKey) {
    return null;
  }
  return (
    <Modal isOpen={Boolean(apiKey)} variant="narrow">
      <Modal.CloseIcon onClose={onClose} />
      <Modal.Title variant="green">Your new API key</Modal.Title>

      <form
        onSubmit={(e) => {
          const formData = new FormData(e.target as HTMLFormElement);
          formData.set("apiKey", apiKey);
          const formJson = Object.fromEntries(formData.entries()) as ApiKeyData;
          onSubmit(formJson);
          e.preventDefault();
        }}
      >
        <div className="mb-4">
          <Input
            id="api-key"
            name="apiKey"
            labelText="API key"
            value={apiKey}
            disabled={true}
            enableCopy={true}
          />
        </div>
        {/* <Input
          id="api-key-name"
          name="keyName"
          required
          placeholder="My little key"
          labelText="Friendly name (optional)"
          description="You can give your key an informal name to help you remember its purpose. You can edit this at any time."
        /> */}
        <Body1>
          <b>Store your key now.</b> To protect your security, this is the only
          time we'll display your full API key. Copy it now and store it
          securely.
        </Body1>
        <p className="mt-4 flex justify-end">
          {/* <div className="mr-4">
            <ButtonLarge onClick={() => onClose()} variant={Variant.Secondary}>
              Cancel
            </ButtonLarge>
          </div> */}
          <ButtonLarge type="submit" variant={Variant.Primary}>
            Done
          </ButtonLarge>
        </p>
      </form>
    </Modal>
  );
};
