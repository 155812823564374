import { ReactComponent as BaseExclamation } from "./BaseExclamation.svg";
import style from "./Exclamation.module.css";

type Props = {
  variant: "yellow" | "red";
};

export const Exclamation = ({ variant }: Props) => {
  return (
    <span aria-label="Exclamation icon" className={style[`container_${variant}`]}>
      <BaseExclamation />
    </span>
  );
};
