
import classNames from "classnames";

import style from "./ButtonLarge.module.css";
import { Button1Text } from "../text/Button1Text";

export enum Variant {
  DisabledDark,
  Primary,
  Secondary,
}

type Props = {
  variant: Variant;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
  isFullWidth?: boolean;
};
export const ButtonLarge = ({
  children,
  variant,
  iconLeft,
  iconRight,
  isFullWidth = false,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & Props) => {
  return (
    <button
      disabled={variant === Variant.DisabledDark}
      className={classNames(style.button, {
        [style.disabled_dark]: variant === Variant.DisabledDark,
        [style.primary]: variant === Variant.Primary,
        [style.secondary]: variant === Variant.Secondary,
        [style.full_width]: isFullWidth,
      })}
      {...props}
    >
      <div className={style.content_container}>
        {iconLeft && <span className={style.icon}>{iconLeft}</span>}
        <Button1Text fontWeight={600}>{children}</Button1Text>
        {iconRight && <span className={style.icon}>{iconRight}</span>}
      </div>
    </button>
  );
};
