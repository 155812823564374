import { Properties } from "csstype";

import style from "./Link1.module.css";
import classNames from "classnames";

import { ReactComponent as ExternalLink } from "../../../assets/icons/ExternalLink.svg";
import { Link, LinkProps } from "react-router-dom";

export enum Variant {
  Primary = "primary",
  Secondary = "secondary",
}

type Props = {
  variant?: Variant;
  fontWeight?: Properties["fontWeight"];
};

export const Link1 = ({
  children,
  variant = Variant.Primary,
  fontWeight = 600,
  target,
  ...props
}: LinkProps & Props) => {
  const additionalStyles: Pick<Props, "fontWeight"> = { fontWeight };

  const classes = classNames(style.text, style[variant]);

  const isExternalLink =
    target?.includes("_blank") || props.to.toString().startsWith("http");

  return (
    <Link
      {...props}
      className={classes}
      style={additionalStyles}
      target={target ?? isExternalLink ? "_blank" : undefined}
    >
      {children}
      {isExternalLink && <ExternalLink className="inline mx-1" />}
    </Link>
  );
};
