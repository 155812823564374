import { MainLayoutContainer } from "../components/organisms/homepage/MainLayoutContainer";
import { Header } from "../components/atoms/text/Header";
import { NumberedListItem } from "../components/molecules/NumberedListItem";
import { Link1 } from "../components/atoms/text/Link1";
import { Body1 } from "../components/atoms/text/Body1";
import { SIGAPI_DOCS_URL } from "../constants";
import { Divider } from "../components/atoms/Dividers/Divider";

export default function Index() {
  return (
    <MainLayoutContainer title="Home">
      <Header variant="green">Get started</Header>
      <NumberedListItem index={1}>
        Generate an <Link1 to="/api-keys">API key</Link1>.
      </NumberedListItem>
      <NumberedListItem index={2}>
        Build your first SigTech strategy on our{" "}
        <Link1 to={SIGAPI_DOCS_URL}>interactive docs</Link1>.
      </NumberedListItem>
      <NumberedListItem index={3}>
        Clone our{" "}
        <Link1 to="https://github.com/SIGTechnologies/sigtech-python">
          Python SDK
        </Link1>{" "}
        and make some serious Alpha.
      </NumberedListItem>
      <Body1>
        Remember you’re in our select group of beta testers and can use the API
        for free. Make the most of it!
      </Body1>
      <Divider className="my-6" />
      <Header variant="blue">Help and feedback</Header>
      <Body1>
        For general guidance, check out our{" "}
        <Link1 to={SIGAPI_DOCS_URL}>docs</Link1>. If you have any questions or
        comments, we’d love to hear from you on{" "}
        <Link1 to="https://discord.gg/yfTqrm2ubz">Discord</Link1> and{" "}
        <Link1 to="https://twitter.com/sigtechltd">Twitter</Link1>.
      </Body1>
      <div className="flex justify-end">
        <img className="max-h-64" src={"/images/FluffGraphic.svg"} />
      </div>
    </MainLayoutContainer>
  );
}
