import { RuleItemAction } from "./RuleItemAction";
import style from "./RuleItemActions.module.css";

type Props = {
  children: React.ReactElement<typeof RuleItemAction> | React.ReactElement<typeof RuleItemAction>[];
};

export const RuleItemActions = ({ children }: Props) => {
  return (
    <div className={style.container} data-rule-item-actions>
      {children}
    </div>
  );
};
