import { ReactComponent as BaseTick } from "./BaseTick.svg";
import style from "./Tick.module.css";

type Props = {
  variant: "green" | "dark_green";
};
export const Tick = ({ variant }: Props) => {
  return (
    <span aria-label="Tick icon" className={style[`container_${variant}`]}>
      <BaseTick />
    </span>
  );
};
