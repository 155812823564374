import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useInterval } from "@mantine/hooks";
import VerifyEmail from "../components/organisms/auth/VerifyEmail";

export default function Verify() {
  const navigate = useNavigate();
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (user?.email_verified) {
      navigate("/");
    }
  }, [isAuthenticated, navigate, user?.email, user?.email_verified]);

  useInterval(() => {
    getAccessTokenSilently({ cacheMode: "off" });
  }, 2000)

  if (!user?.email) {
    return (
      <div>
        <span>
          Oops! There seems to be an issue with your account, please contact{" "}
          <a href="mailto:support@sigtech.com">support@sigtech.com</a>
        </span>
      </div>
    );
  }

  return (
    <div className="w-full h-full flex justify-center align-middle">
      <VerifyEmail email={user?.email} />
    </div>
  );
}
