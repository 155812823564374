

import style from "./Title3.module.css";
import { Properties } from "csstype";

type Props = {
  fontWeight?: Properties["fontWeight"];
};

export const Title3 = ({ fontWeight = 400, children, ...props }: React.HTMLAttributes<HTMLHeadingElement> & Props) => {
  const additionalStyles = { fontWeight };

  return (
    <h3 className={style.text} style={additionalStyles} {...props}>
      {children}
    </h3>
  );
};
