
import { Properties } from "csstype";

import style from "./Button1Text.module.css";

type Props = {
  fontWeight?: Properties["fontWeight"];
};

export const Button1Text = ({ children, fontWeight, ...props }: React.HTMLAttributes<HTMLParagraphElement> & Props) => {
  const additionalStyles: Pick<Props, "fontWeight"> = {};

  if (fontWeight) {
    additionalStyles["fontWeight"] = fontWeight;
  }

  return (
    <span className={style.text} style={additionalStyles} {...props}>
      {children}
    </span>
  );
};
