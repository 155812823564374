import { toast as reactToast, ToastOptions } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import style from "./Toast.module.css";

import { Body2 } from "../text/Body2";
import classNames from "classnames";
import { getIconForVariant } from "./utils";

export type MyToastProps = {
  text: string;
  variant: "success" | "error" | "info";
};

const CustomToast = ({ text, variant }: MyToastProps) => (
  <>
    <div className={style.container}>
      <div className={style.title_icon_container}>
        <div className={style.icon}>{getIconForVariant(variant)}</div>
        <Body2 fontWeight={500}>
          <span
            className={classNames(style.text_max_width, {
              [style[`${variant}_text`]]: variant,
            })}
          >
            {text}
          </span>
        </Body2>
      </div>
      <div
        className={classNames(style.divider, {
          [style[`${variant}_border`]]: variant,
        })}
      />
    </div>
  </>
);

export const toast = ({ options, ...props }: MyToastProps & { options?: ToastOptions }) => {
  reactToast(<CustomToast {...props} />, options);
};
