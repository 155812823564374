import { ReactComponent as APILogo } from "../../../../assets/logos/APILogo.svg";
import { Title1 } from "../../../atoms/text/Title1";
import { Title3 } from "../../../atoms/text/Title3";
import { ReactComponent as Mail } from "../../../../assets/icons/Mail.svg";
import style from "./index.module.css";

type VerifyEmailProps = {
  email: string;
};

const VerifyEmail = ({ email }: VerifyEmailProps) => (
  <div className={style.container}>
    <APILogo />
    <Mail />
    <Title1 fontWeight={600}>Verify your email address</Title1>
    <div className={style.text_block}>
      <Title3>
        Please check <strong>{email}</strong> for an
        <br />
        email that activates your account.
      </Title3>
    </div>
  </div>
);

export default VerifyEmail