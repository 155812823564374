import { useAuth0 } from "@auth0/auth0-react";

import styles from "./UserDropdown.module.css";

export const UserDropDown = ({ name }: { name: string }) => {
  const { logout } = useAuth0();

  const logoutWithRedirect = () => logout();

  return (
    <div className={styles.dropdown_container}>
      <div className={styles.dropdown_inner_container}>
        <p className={styles.dropdown_name}>{name.toUpperCase()}</p>
        <div className={styles.dropdown_links_container}>
          <p onClick={logoutWithRedirect} className={styles.dropdown_link}>
            Log out
          </p>
        </div>
      </div>
    </div>
  );
};
