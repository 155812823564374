import { useAuth0 } from "@auth0/auth0-react";
import { LoadingSpinner } from "../components/molecules/LoadingSpinner/LoadingSpinner";

export default function Register() {
    const { loginWithRedirect } = useAuth0();
    loginWithRedirect({
        authorizationParams: {
          screen_hint: "signup",
        },
    });
    return (
      <div className="flex justify-center mt-64 h-16">
        <LoadingSpinner />
      </div>
    );
}