import { ReactComponent as BaseInfo } from "./BaseInfo.svg";
import style from "./Info.module.css";

type Props = {
  variant: "blue" | "dark_blue" | "grey" | "red";
};
export const Info = ({ variant }: Props) => {
  return (
    <span aria-label={"Information icon"} className={style[`container_${variant}`]}>
      <BaseInfo />
    </span>
  );
};
