import { Link } from "react-router-dom";
import styles from "./index.module.css";
import { ReactComponent as ExternalLink } from "../../../assets/icons/ExternalLink.svg";
import { useMemo } from "react";

export const LeftNavButton = ({
  href,
  label,
  icon,
}: {
  href: string;
  label: string;
  icon: React.ReactElement;
}) => {
  const currentPathName = useMemo(
    () => new URL(window.location.href).pathname,
    []
  );
  const isExternalLink = !href.startsWith("/");
  return (
    <div
      className={`${styles.leftnavbutton} ${
        currentPathName === href ? styles.leftnavbuttonSelected : ``
      }`}
    >
      <Link
        target={isExternalLink ? "_blank" : undefined}
        className="flex justify-start items-center gap-3 p-2 rounded-lg text-sm font-semibold leading-5"
        to={href}
      >
        <span>{icon}</span>
        <span>{label}</span>
        {isExternalLink && (
          <span className="ml-auto">
            <ExternalLink className="inline" />
          </span>
        )}
      </Link>
    </div>
  );
};
