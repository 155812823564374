import  { ReactNode } from "react";

import style from "./Table.module.css";
import { Body2 } from "../../../../atoms/text/Body2";

// This table is specific to the schema page. There are other tables in the project which look similar
// The design team are working on making them standard. Once they have done that we can refactor this and move into
// the molecules folder and make it more generic

export type TableProps = {
  headers: string[];
  data: { key: string; value: string | ReactNode }[][];
};

export const Table = ({ headers, data }: TableProps) => {
  return (
    <div className={style.container}>
      <table>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>
                <div className={style.column_heading}>
                  <Body2 fontWeight={600}>{header}</Body2>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row) => (
            <tr key={row.map((cell) => cell.key).join(",")}>
              {row.map(({ key, value }, index) => (
                <td key={`${key}-${index}`}>
                  <div className={style.table_cell_container}>{value}</div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
