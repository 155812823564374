import { MainLayoutContainer } from "../components/organisms/homepage/MainLayoutContainer";
import { Spacer } from "../components/atoms/Dividers/Spacer";
import { Header } from "../components/atoms/text/Header";
import { Body1 } from "../components/atoms/text/Body1";

export default function Billing() {
  return (
    <MainLayoutContainer title="Billing">
      <Header variant="green">Manage your billing information</Header>
      <Body1>
        You’re one of our lucky beta testers, which means you don’t need to
        worry about bills for now — all your API calls are currently free of
        charge.{" "}
      </Body1>
      <Body1>
        In the future, this is where you’ll be able to view and modifying your
        payment details.
      </Body1>
      <Spacer />
    </MainLayoutContainer>
  );
}
