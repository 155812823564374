import style from "./ErrorMessage.module.css";
import { Body2 } from "../../atoms/text/Body2";
import { Info } from "../../../assets/icons/variants/Info/Info";

type Props = {
  children: React.ReactNode;
};

export const ErrorMessage = ({ children }: Props) => {
  return (
    <div className={style.container}>
      <Info aria-label="Info icon" variant={"red"} />
      <div className={style.message_body}>
        <Body2 lineHeight="17px">{children}</Body2>
      </div>
    </div>
  );
};
