import classNames from "classnames";
import ReactModal from "react-modal";
import style from "./Modal.module.css";

import { ReactComponent as CloseCross } from "../../../assets/icons/CloseCross.svg";

import { Link1, Variant as LinkVariant } from "../text/Link1";
import { Body2 } from "../text/Body2";
import { Info } from "../../../assets/icons/variants/Info/Info";
import { Header, HeaderProps } from "../text/Header";

type Props = {
  children: React.ReactNode;
  variant?: "narrow" | "wide";
};

type SectionProps = {
  children: React.ReactNode | React.ReactNode[];
};

export const Modal = ({
  children,
  variant = "wide",
  ...props
}: Props & ReactModal.Props) => {
  const modalClasses = classNames(style.container, {
    [style.container_wide]: variant === "wide",
    [style.container_narrow]: variant === "narrow",
  });

  return (
    <ReactModal
      {...props}
      ariaHideApp={false}
      className={modalClasses}
      overlayClassName={style.modal_overlay}
    >
      {children}
    </ReactModal>
  );
};

type CloseIconProps = {
  onClose: () => void;
};

/* Namespaced child components below */

Modal.CloseIcon = ({ onClose }: CloseIconProps) => {
  return (
    <div className={style.close_icon_container}>
      <button
        onClick={onClose}
        aria-label="Close"
        className={style.close_cross_icon}
      >
        <CloseCross />
      </button>
    </div>
  );
};

Modal.Title = ({
  children,
  variant = "blue",
  link,
  linkLabel,
}: HeaderProps) => {
  return (
    <div className={style.title_container}>
      <Header variant={variant}>{children}</Header>
      {link ? (
        <div className={style.title_container_link}>
          <Info variant={"grey"} />
          <Link1
            variant={LinkVariant.Secondary}
            target="_blank"
            rel="noreferrer"
            to={link}
          >
            <Body2>{linkLabel}</Body2>
          </Link1>
        </div>
      ) : null}
    </div>
  );
};

Modal.Section = ({ children }: SectionProps) => {
  return <div className={style.section_container}>{children}</div>;
};

Modal.Divider = () => {
  return <hr className={style.section_divider} />;
};
