

import style from "./Title1.module.css";
import { Properties } from "csstype";

type Props = {
  fontWeight?: Properties["fontWeight"];
};

export const Title1 = ({ fontWeight = 600, children, ...props }: React.HTMLAttributes<HTMLHeadingElement> & Props) => {
  const additionalStyles: Pick<Props, "fontWeight"> = { fontWeight };

  return (
    <h1 className={style.text} style={additionalStyles} {...props}>
      {children}
    </h1>
  );
};

export default Title1;