import { MyToastProps } from "./Toast";
import { Info } from "../../../assets/icons/variants/Info/Info";
import { Exclamation } from "../../../assets/icons/variants/Exclamation/Exclamation";
import { Tick } from "../../../assets/icons/variants/Tick/Tick";

export const getIconForVariant = (variant: MyToastProps["variant"]) => {
  switch (variant) {
    case "success":
      return <Tick variant={"green"} />;
    case "error":
      return <Exclamation variant={"red"} />;
    case "info":
      return <Info variant={"blue"} />;
    default:
      return null;
  }
};
