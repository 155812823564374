import { useState } from "react";
import { ButtonLarge, Variant } from "../../atoms/Buttons/ButtonLarge";
import { Modal } from "../../atoms/modal/Modal";
import { Checkbox } from "../../atoms/input/Checkbox";
import { Link1 } from "../../atoms/text/Link1";

export type Policies = {
    termsAndConditions: boolean;
    privacyNotice: boolean;
};

type PolicyModalProps = {
    onClose?: () => void;
    onSubmit: (formData: Policies) => void;
    disablePrimaryButton?: boolean;
} & Policies;

export const PolicyModal = ({
    onClose,
    onSubmit,
    disablePrimaryButton,
    termsAndConditions,
    privacyNotice,
}: PolicyModalProps) => {
    const [acceptedTerms, setAcceptedTerms] = useState(termsAndConditions);
    const [acceptedPrivacyNotice, setAcceptedPrivacyNotice] =
        useState(privacyNotice);

    const disableSubmit = disablePrimaryButton || !acceptedTerms || !acceptedPrivacyNotice;

    return (
        <Modal isOpen={true} variant="narrow">
            {onClose ? <Modal.CloseIcon onClose={onClose} /> : null}
            <Modal.Title variant="green">The Legal Bit</Modal.Title>
            <div> Please read and accept the latest version of our legal docs.</div>

            <form
                className="mt-4"
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit({
                        termsAndConditions: acceptedTerms,
                        privacyNotice: acceptedPrivacyNotice,
                    });
                }}
            >
                <div className="mb-4">
                    <Checkbox
                        name={"termsAndConditions"}
                        checked={acceptedTerms}
                        onChange={() => setAcceptedTerms((prev) => !prev)}
                    >
                        By checking this box, you agree to the{" "}
                        <Link1 to="https://sigtech.com/api-terms-of-service/" rel="noreferrer" target="_blank">
                            SigTech API terms of service
                        </Link1>
                    </Checkbox>
                </div>
                <div className="mb-4">
                    <Checkbox
                        name={"privacyNotice"}
                        checked={acceptedPrivacyNotice}
                        onChange={() => setAcceptedPrivacyNotice((prev) => !prev)}
                    >
                        By checking this box, you agree to the{" "}
                        <Link1 to="https://sigtech.com/privacy/" rel="noreferrer" target="_blank">
                            privacy notice
                        </Link1>
                    </Checkbox>
                </div>
                <Modal.Divider />
                <p className="mt-4 flex justify-center">
                    <ButtonLarge type="submit" variant={disableSubmit ? Variant.DisabledDark : Variant.Primary} >
                        Accept and continue
                    </ButtonLarge>
                </p>
            </form>
        </Modal>
    );
};
