
import classNames from "classnames";

import style from "./ButtonSmall.module.css";
import { Button1Text } from "../text/Button1Text";

export enum Variant {
  DisabledDark,
  Quaternary,
  Tertiary,
}

type Props = {
  variant: Variant;
  iconRight?: React.ReactNode;
  iconLeft?: React.ReactNode;
};
export const ButtonSmall = ({
  children,
  variant,
  iconLeft,
  iconRight,
  ...props
}: React.ButtonHTMLAttributes<HTMLButtonElement> & Props) => {
  return (
    <button
      className={classNames(style.button, {
        [style.disabled_dark]: variant === Variant.DisabledDark,
        [style.quaternary]: variant === Variant.Quaternary,
        [style.tertiary]: variant === Variant.Tertiary,
      })}
      {...props}
    >
      <div className={style.content_container}>
        {iconLeft && <div className={style.icon}>{iconLeft}</div>}
        <Button1Text fontWeight={600}>{children}</Button1Text>
        {iconRight && <div className={style.icon}>{iconRight}</div>}
      </div>
    </button>
  );
};
