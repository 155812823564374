import style from "./RulesGroup.module.css";
import { RuleItem } from "./RuleItem";
import { Title1 } from "../../../../atoms/text/Title1";
import { RuleItemActions } from "./RuleItemActions";
import { RuleItemAction } from "./RuleItemAction";

type Props = {
  children: React.ReactElement<typeof RuleItem> | React.ReactElement<typeof RuleItem>[];
  label: string;
};

export const RulesGroup = ({ children, label }: Props) => {
  return (
    <div className={style.container} data-rules-group-containter>
      <div className={style.label} data-rules-group-category-label>
        <Title1>{label}</Title1>
      </div>
      <div className={style.rules} data-rules-group-rules>
        {children}
      </div>
    </div>
  );
};

RulesGroup.Item = RuleItem;
RulesGroup.ItemActions = RuleItemActions;
RulesGroup.ItemAction = RuleItemAction;
