import styles from "./Navbar.module.css";
import { useAuth0 } from "@auth0/auth0-react";
import { Burger, Drawer } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { UserDropDown } from "../../molecules/UserDropDown";
import { ReactComponent as AnonymousUserIcon } from "../../../assets/icons/AnonymousUser.svg";
import { Link } from "react-router-dom";
import { SIGAPI_DOCS_URL } from "../../../constants";
import { LeftNavButton } from "../../molecules/LeftNavButton";

import { ReactComponent as APILogo } from "../../../assets/logos/APILogo.svg";
import { ReactComponent as Home } from "../../../assets/icons/Home.svg";
import { ReactComponent as Key } from "../../../assets/icons/Key.svg";
import { ReactComponent as UsageIcon } from "../../../assets/icons/Usage.svg";
import { ReactComponent as BillingIcon } from "../../../assets/icons/Billing.svg";
import { ReactComponent as Document } from "../../../assets/icons/Document.svg";

export const Navbar = () => {
  const { user } = useAuth0();
  const [opened, { close, toggle }] = useDisclosure(false);

  const label = opened ? "Close navigation" : "Open navigation";

  return (
    <nav className={styles.navbar}>
      <Burger
        opened={opened}
        onClick={toggle}
        aria-label={label}
        className="block md:hidden"
      />
      <Drawer opened={opened} onClose={close} className="block md:hidden">
        <SideBar />
      </Drawer>
      <div className={styles.right_aligned}>
        <div className={styles.dropdown_user_container}>
          <div
            className={styles.anonymous_user_icon_container}
            data-testid="anonymous-icon"
          >
            <AnonymousUserIcon />
          </div>
          <div className={styles.dropdown_content}>
            <UserDropDown name={user?.name ?? ""} />
          </div>
        </div>
      </div>
    </nav>
  );
};

export const SideBar = ({ className }: { className?: string }) => {
  return (
    <div className={`h-full min-w-[204px] ${className ?? ""}`}>
      <div className="p-6">
        <Link to="/">
          <APILogo className="mx-auto" />
        </Link>
      </div>
      <div className="p-6">
        <section>
          <LeftNavButton icon={<Home />} href="/" label="Home" />
          <LeftNavButton icon={<Key />} href="/api-keys" label="API keys" />
          <LeftNavButton href="/usage" icon={<UsageIcon />} label="Usage" />
          <LeftNavButton
            href="/billing"
            icon={<BillingIcon />}
            label="Billing"
          />
          <LeftNavButton
            href={SIGAPI_DOCS_URL}
            icon={<Document />}
            label="Docs"
          />
        </section>
      </div>
    </div>
  );
};
