import * as React from "react";
import { MantineProvider } from "@mantine/core";
import { Navbar, SideBar } from "../navbar/Navbar";

export const MainLayoutContainer = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <div className="overflow-x-hidden">
        <main className="h-screen grid grid-cols-[auto_1fr]">
          <SideBar className="hidden md:block" />
          <div className="min-h-screen bg-[color:var(--grey-03)] overflow-x-hidden">
            <Navbar />
            <section className="px-[40px] py-[32px]">
              <h1>{title}</h1>
              <div className="p-8 bg-white rounded-lg">{children}</div>
            </section>
          </div>
        </main>
      </div>
    </MantineProvider>
  );
};
