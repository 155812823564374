import { ButtonSmall, Variant } from "../../../../atoms/Buttons/ButtonSmall";
import { ReactComponent as Pencil } from "../../../../../assets/icons/Pencil.svg";
import { ReactComponent as TrashCan } from "../../../../../assets/icons/TrashCan.svg";
import { ReactComponent as Plus } from "../../../../../assets/icons/Plus.svg";
import { LoadingSpinner } from "../../../../molecules/LoadingSpinner/LoadingSpinner";

export enum ActionType {
  Add = "Add",
  Edit = "Edit",
  Delete = "Delete",
}

export type OnActionEvent = {
  actionType: ActionType;
  ruleKey: string;
};

type Props = {
  actionType: ActionType;
  variant: Variant;
  ruleKey: string;
  onAction: (e: OnActionEvent) => void;
  isActionLoading?: boolean;
  label?: string;
};

type Action = {
  icon: React.ReactElement;
  label: string;
};

const actionMap: Record<ActionType, Action> = {
  [ActionType.Add]: { icon: <Plus />, label: "Add" },
  [ActionType.Edit]: { icon: <Pencil />, label: "Edit" },
  [ActionType.Delete]: { icon: <TrashCan />, label: "Delete" },
};

export const RuleItemAction = ({
  actionType,
  variant,
  ruleKey,
  onAction,
  isActionLoading,
  label,
}: Props) => {
  const onActionClick = () => {
    onAction({ actionType, ruleKey });
  };

  return (
    <div data-rule-item-action>
      <ButtonSmall
        aria-label={`${actionType} ${ruleKey}`}
        onClick={onActionClick}
        iconLeft={
          isActionLoading ? <LoadingSpinner /> : actionMap[actionType].icon
        }
        variant={variant}
      >
        {label ?? actionMap[actionType].label}
      </ButtonSmall>
    </div>
  );
};
