import { ReactComponent as Chart } from "../assets/icons/Chart.svg";

import { MainLayoutContainer } from "../components/organisms/homepage/MainLayoutContainer";
import { Spacer } from "../components/atoms/Dividers/Spacer";
import { Header } from "../components/atoms/text/Header";
import { Body1 } from "../components/atoms/text/Body1";

export default function Usage() {
  return (
    <MainLayoutContainer title="Usage">
      <Header variant="green">Manage your usage</Header>
      <Body1>
        In the future, this is where you’ll be able to monitor how you’ve been
        using the API.
      </Body1>
      <Spacer />
      <div className="bg-[var(--grey-02)] rounded-lg">
        <Chart style={{ width: "100%" }} />
      </div>
    </MainLayoutContainer>
  );
}
