import useSWRMutation from "swr/mutation";
import { swrMutater } from "../../libs/fetcher";
import { useAuth0 } from "@auth0/auth0-react";
import { Key } from "swr";

export const useUpdatePolicies = () => {
const { getAccessTokenSilently } = useAuth0();
  return useSWRMutation<{status: 'ok'}, any, Key, {body: string}>(
    "policies",
    swrMutater("/policies", getAccessTokenSilently)
  );
};
