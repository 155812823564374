import { Properties } from "csstype";

import style from "./Body1.module.css";

type Props = {
  fontWeight?: Properties["fontWeight"];
};

export const Body1 = ({
  children,
  fontWeight,
  className,
  ...props
}: React.HTMLAttributes<HTMLParagraphElement> & Props) => {
  const additionalStyles: Pick<Props, "fontWeight"> = { fontWeight };

  if (fontWeight) {
    additionalStyles["fontWeight"] = fontWeight;
  }

  return (
    <p
      {...props}
      className={`${style.text} mb-3 ${className}`}
      style={additionalStyles}
    >
      {children}
    </p>
  );
};
