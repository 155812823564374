import classNames from "classnames";
import { Title1 } from "./Title1";
import style from "./Header.module.css";
import { ReactNode } from "react";

export type TitleBlockColour = "none" | "blue" | "green";

// enforce conditional application of modal title link props via discriminated union. For example, if `link` is supplied, then `linkLabel` must also be supplied, and vice versa.
type ConditionalTitleLinkProps =
  | { link?: false; linkLabel?: never }
  | { link: string | undefined; linkLabel: string };

export type HeaderProps = {
  children: ReactNode;
  variant?: TitleBlockColour;
} & ConditionalTitleLinkProps;

export function Header({ children, variant }: HeaderProps) {
  const titleBlockClass = classNames(style.title_container_title_block, {
    [style[`title_container_title_block_${variant}`]]: variant !== "none",
  });

  return (
    <div className={titleBlockClass}>
      <Title1 fontWeight={600}>{children}</Title1>
    </div>
  );
}
