import classNames from "classnames";
import { forwardRef } from "react";

import style from "./index.module.css";
import { Title3 } from "../../text/Title3";

type ContainerProps = JSX.IntrinsicElements["label"];

type Props = JSX.IntrinsicElements["input"] & {
  containerProps?: ContainerProps;
  hasError?: boolean;
};

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ children, hasError, containerProps, ...inputProps }: Props, ref) => (
    <label {...containerProps} className={classNames(style.container, containerProps?.className)}>
      <input
        {...inputProps}
        className={classNames(style.input, hasError && style.error, inputProps.className)}
        ref={ref}
        type="checkbox"
      />
      <Title3 fontWeight={500}>{children}</Title3>
    </label>
  )
);
