import classNames from "classnames";
import React from "react";
import style from "./NumberedListItem.module.css";
import { Body2 } from "../atoms/text/Body2";
import { Body1 } from "../atoms/text/Body1";

export const NumberedListItem = ({
  children,
  index,
}: {
  children: React.ReactNode;
  index: number;
}) => (
  <div className={style.section_container}>
    <div className={classNames(style.number, style.active_number)}>
      <Body2 fontWeight={600}>{index}</Body2>
    </div>

    <Body1 className={classNames(style.name_container, style.active_name)}>
      {children}
    </Body1>
  </div>
);
