import { useCallback } from "react"
import { ReactComponent as CopyToClipboard } from "../../../assets/icons/CopyToClipboard.svg"
import { useClipboard } from "use-clipboard-copy"
import { toast } from "../toast/Toast"
import style from "./Input.module.css";
import classNames from "classnames";
import { ErrorMessage } from "../../molecules/ErrorMessage/ErrorMessage";
import { Info } from "../../../assets/icons/variants/Info/Info";
import { forwardRef } from "react";

type Props = (
  | {
      placeholder: string
      labelText?: string
    }
  | {
      placeholder?: string
      labelText: string
    }
) & {
  displayInfoLogo?: boolean
  errorMessage?: string
  description?: string
  // Required for testable aria label and description
  id: string
  enableCopy?: boolean
} & Omit<JSX.IntrinsicElements["input"], "placeholder">

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      labelText,
      errorMessage,
      placeholder,
      id,
      displayInfoLogo,
      description,
      ...props
    },
    ref
  ) => {
    const clipboard = useClipboard({
      onSuccess() {
        toast({
          text: "Key successfully copied",
          variant: "success",
        })
      },
      onError() {
        console.log("Failed to copy text!")
      },
    })

    const handleCopy = useCallback(() => {
      clipboard.copy(props.value)
    }, [clipboard, props.value])

    return (
      <div className={style.container}>
        {labelText && (
          <div className={style.project_name_label_container}>
            <label className="font-semibold" htmlFor={id}>
              {labelText || placeholder}
            </label>

            {displayInfoLogo && (
              <div className={style.name_information_icon}>
                <Info variant={"grey"} />
              </div>
            )}
          </div>
        )}

        <div className="my-1" hidden={!description}>
          {description}
        </div>
        <div className={style.input_container}>
          <input
            {...props}
            id={id}
            placeholder={placeholder}
            ref={ref}
            // https://www.w3.org/WAI/WCAG21/Techniques/aria/ARIA21
            aria-invalid={!!errorMessage}
            aria-describedby={errorMessage && `error-description-${id}`}
            className={classNames(style.input, errorMessage && style.error)}
          />
          {props.enableCopy && (
            <div className={style.input_copy_to_clipboard} onClick={handleCopy}>
              <CopyToClipboard />
            </div>
          )}
        </div>
        {errorMessage && (
          <div className={style.error_message}>
            <ErrorMessage>
              <span id={`error-description-${id}`}>{errorMessage}</span>
            </ErrorMessage>
          </div>
        )}
      </div>
    )
  }
)
